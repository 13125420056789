import {Component, OnInit} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
    selector: 'app-aps-error',
    templateUrl: './aps-error.component.html',
    styleUrls: ['./aps-error.component.scss'],
    standalone: true
})
export class ApsErrorComponent implements OnInit {

  message = '';

  constructor(private _route: ActivatedRoute) {
  }

  ngOnInit() {
    this.message = this._route.snapshot.paramMap.get('message');
  }

}
